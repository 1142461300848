import { Field, FieldArray, Form, Formik } from "formik";
import { Component, Fragment } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BulkOwsOverridePayload,
  BulkUpdatePayload,
  Dow,
  IBulkManualRateUpdate,
  IBulkRewardsUpdate,
  IManualRateUpdate,
  IRestrictionsUpdate,
} from "../../models/api.models";
import { YieldSheetService } from "../services";
import { appStore } from "../store";
import { BulkUpdatePreview } from "./bulk-update-preview";
import { restrictions } from "gn-shared";
import moment from "moment";
import { Button, TextField, Dialog, DialogTitle } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import LoadingButton from "@mui/lab/LoadingButton";
import Popup from "../../reusable-components/popup/popup";
import "./bulk-update.css";
import { getFieldClasses } from "../common/forms";
import { YieldApi } from "api/yield";

interface PublishPayload {
  start: string;
  end: string;
  baseRate: number;
  weekDays: boolean[];
  owslabel: string;
}

interface Period {
  start: string;
  end: string;
  idx: number;
}

interface BulkData {
  baseRate?: number;
  property: string;
}

interface FormState extends PublishPayload {
  property:
    | "baseRate"
    | "owsBaseRate"
    | "setRate"
    | "APPLY_STOP_SELL"
    | "REMOVE_STOP_SELL"
    | "APPLY_CLOSED_TO_ARRIVAL"
    | "REMOVE_CLOSED_TO_ARRIVAL"
    | "APPLY_CLOSED_TO_DEPARTURE"
    | "REMOVE_CLOSED_TO_DEPARTURE"
    | "APPLY_MIN_DAYS#2"
    | "REMOVE_MIN_DAYS"
    | "OPEN_REWARDS"
    | "CLOSE_REWARDS"
    | "OVERIDE_OWS";
  allDayCheked: boolean;
}

interface State {
  toYieldSheet: boolean;
  displayWarning: boolean;
  displaySpinner: boolean;
  restrictionSelections: any;
  allDaySelected: boolean;
  hotel: any;
  periods: Period[];
  defaultOwsLabel: string;
  popupStatus: boolean;
}

interface bulkUpdateProps {
  activeHotelId: number;
  isPopupOpen: boolean;
  popupState: (state: boolean) => void;
}

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

class BulkUpdate extends Component<bulkUpdateProps, State> {
  readonly state: State = {
    hotel: {},
    toYieldSheet: false,
    displayWarning: false,
    displaySpinner: false,
    restrictionSelections: {},
    allDaySelected: true,
    defaultOwsLabel: "Default",
    popupStatus: false,

    periods: [
      {
        idx: 0,
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
    ],
  };

  constructor(props: any) {
    super(props);
    this.state.hotel = appStore.meta.config.hotels[this.props.activeHotelId];
    this.applyToYieldSheet = this.applyToYieldSheet.bind(this);
    if (typeof this.state.hotel.cm.channelConfig.ows.label !== "undefined") {
      this.state.defaultOwsLabel = this.state.hotel.cm.channelConfig.ows.label;
    }

    this.state.popupStatus = this.props.isPopupOpen;
  }

  addPeriod = () => {
    let periods = this.state.periods;
    periods.push({
      idx: periods.length,
      start: moment().format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    });
    this.setState({ periods });
  };
  removePeriod = () => {
    let periods = this.state.periods;
    periods.pop();
    this.setState({ periods });
  };

  applyToYieldSheet(data: any) {
    console.log("data", data);

    if (data.values.baseRate <= 0) {
      toast("Please check the highlited field", { type: "error" });
      return;
    } else {
      let formDataValues = data.values;
      this.setState({ displaySpinner: true });
      let payloads;
      let promises;
      switch (formDataValues.property) {
        case "OVERIDE_OWS":
          payloads = this.prepareOverideOwsPayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldApi.bulkUpdateOwsOverride(payload),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating OWS overrides");
            });
          break;
        case "baseRate":
          payloads = this.prepareBaseRatePayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldSheetService.revanista.yieldsheet.days.bulkUpdateRates(
              payload,
              this.props,
            ),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating rates");
            });
          break;
        case "owsBaseRate":
          console.log("hp");
          payloads = this.prepareOWSBaseRatePayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldSheetService.revanista.yieldsheet.days.bulkUpdateRates(
              payload,
              this.props,
            ),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating rates");
            });
          break;
        case "setRate":
          payloads = this.prepareSetRatePayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldSheetService.revanista.yieldsheet.days.bulkUpdateManualRates(
              payload,
              this.props,
            ),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating rates");
            });
          break;
        case "OPEN_REWARDS":
        case "CLOSE_REWARDS":
          payloads = this.prepareRewardsPayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldSheetService.revanista.yieldsheet.days.bulkUpdateRewards(
              payload,
              this.props,
            ),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating restrictions");
            });

          break;
        default:
          this.prepareRestrictionsPayload(formDataValues).then((payloads) => {
            promises = payloads.map((payload) =>
              YieldApi.bulkUpdateRestrictions(payload),
            );
            Promise.all(promises)
              .then(() => {
                this.setState({ displayWarning: true, displaySpinner: false });
              })
              .catch((err: any) => {
                console.error(err);
                this.setState({ displaySpinner: false });
                toast("Error while updating restrictions", { type: "error" });
              });
          });
      }
    }
  }

  discardAndExit() {
    this.props.popupState(false);
  }

  setStartDate = (event: any, id: any) => {
    let date = event.target.value;
    console.log(date);
    this.setState((prevState) => ({
      periods: prevState.periods.map((period) => {
        if (period.idx === id.idx) {
          const newStartDate = moment(date).format("YYYY-MM-DD");
          return { ...period, start: newStartDate };
        }
        return period;
      }),
    }));
  };

  setEndDate = (event: any, id: any) => {
    let date = event.target.value;
    console.log(date);
    this.setState((prevState) => ({
      periods: prevState.periods.map((period) => {
        if (period.idx === id.idx) {
          const newEndDate = moment(date).format("YYYY-MM-DD");
          console.log(date, newEndDate);

          return { ...period, end: newEndDate };
        }
        return period;
      }),
    }));
  };

  validate = (value: any, formState: any) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (value <= 0) {
      error = "Must be positive";
    }

    if (formState.values.property == "setRate") {
    }

    return error;
  };

  render() {
    if (this.state.toYieldSheet) {
      return <Navigate to={`/${this.props.activeHotelId}`} />;
    }

    console.log(this.state.periods);

    let va = this.state.allDaySelected;
    const initialFormValues: FormState = {
      start: "",
      end: "",
      baseRate: 100,
      weekDays: [va, va, va, va, va, va, va],
      allDayCheked: va,
      property: "baseRate",
      owslabel: this.state.defaultOwsLabel,
    };

    let owsOverrideOptions: any = null;

    if (this.state.hotel?.cm?.channelConfig?.ows?.SeasonnalConfig?.formulas) {
      owsOverrideOptions =
        this.state.hotel.cm.channelConfig.ows.SeasonnalConfig.formulas.map(
          (item: any) => (
            <option key={item.label} value={item.label}>
              {item.label}
            </option>
          ),
        );
    }

    return (
      <Dialog
        open={this.props.isPopupOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ backgroundColor: "black", height: "61px" }}
        >
          <span
            style={{
              color: "white",
            }}
          >
            Bulk Update
          </span>
        </DialogTitle>

        <Popup
          isPopupOpen={this.state.displayWarning}
          title="All changes have been applied to the Yield Sheet."
          content={[
            "Please return to the Yield Sheet and publish your changes to make them live.",
          ]}
          action={() => {
            this.setState({ displayWarning: false });
          }}
          actionName="Ok"
          cancelAction={() => {
            this.props.popupState(false);
          }}
          cancelName="Back to yield sheet"
        />

        <div className="formikClass">
          <Formik
            initialValues={initialFormValues}
            onSubmit={() => null}
            enableReinitialize
            // validationSchema={this.validationSchema}
          >
            {(formState) => (
              <Form
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <div className="row">
                  <div style={{ width: "50%" }}>
                    {this.state.periods.map((period) => (
                      <div
                        style={{
                          width: "45%",
                          display: "flex",
                          position: "relative",
                          right: "7%",
                          marginTop: "2vh",
                        }}
                      >
                        <div style={{}}>
                          <div className="form-group ">
                            <TextField
                              defaultValue={
                                this.state.periods[period.idx].start
                              }
                              style={{ width: "100%", marginLeft: "50px" }}
                              variant="outlined"
                              id="outlined-basic"
                              fullWidth
                              label="From"
                              type="date"
                              onChange={(event: any) =>
                                this.setStartDate(event, period)
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                min: moment().format("YYYY-MM-DD"),
                              }}
                            />
                          </div>
                        </div>

                        <div style={{ marginLeft: "5%" }}>
                          <div className="form-group ">
                            <TextField
                              defaultValue={this.state.periods[period.idx].end}
                              style={{ width: "100%", marginLeft: "50px" }}
                              variant="outlined"
                              id="outlined-basic"
                              fullWidth
                              label="To"
                              type="date"
                              onChange={(event: any) =>
                                this.setEndDate(event, period)
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                min: this.state.periods[period.idx].start,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col">
                    <div className="form-group row">
                      <label
                        htmlFor="on"
                        className="col-sm-1 col-form-label text-right"
                      >
                        On
                      </label>

                      <FieldArray
                        name="weekDays"
                        render={(arrayHelpers) => (
                          <div className="col d-flex justify-content-between">
                            {days.map((day, dayIdx) => (
                              <div
                                className="form-check form-check-inline"
                                key={day}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`day_${day}`}
                                  value={day}
                                  checked={formState.values.weekDays[dayIdx]}
                                  onChange={({ target }) =>
                                    arrayHelpers.replace(dayIdx, target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`day_${day}`}
                                >
                                  {day}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col col-sm-3">
                    <Button
                      className="btn btn-secondary"
                      type="button"
                      onClick={this.addPeriod}
                    >
                      <AddIcon />
                    </Button>
                    {this.state.periods.length > 1 && (
                      <Button
                        className="btn btn-secondary"
                        type="button"
                        onClick={this.removePeriod}
                      >
                        <RemoveIcon />
                      </Button>
                    )}
                  </div>
                </div>
                <div className="row align-items-center">
                  <div>
                    <div className="form-group ">
                      <label htmlFor="property">Update</label>

                      <Field
                        component="select"
                        className={getFieldClasses<BulkData>(
                          formState,
                          "property",
                          "lg",
                        )}
                        name="property"
                        id="property"
                      >
                        <option value="baseRate">Base Rate</option>
                        {hasOWSBRRow(this.state.hotel) && (
                          <Fragment>
                            <option value="owsBaseRate">OWS Base Rate</option>
                          </Fragment>
                        )}
                        <Fragment>
                          <option value="OVERIDE_OWS">OWS Override</option>
                        </Fragment>
                        <option value="setRate">Set Rate</option>
                        <option value="APPLY_STOP_SELL">+ Stop Sell</option>
                        <option value="REMOVE_STOP_SELL">- Stop Sell</option>
                        <option value="APPLY_MIN_DAYS#2">+ Min LOS 2</option>
                        <option value="REMOVE_MIN_DAYS">- Min LOS</option>

                        {this.state.hotel.meta.hasRewards && (
                          <Fragment>
                            <option value="CLOSE_REWARDS">Close Rewards</option>
                            <option value="OPEN_REWARDS">Open Rewards</option>
                          </Fragment>
                        )}
                        <option value="APPLY_CLOSED_TO_ARRIVAL">
                          + Closed To Arrival
                        </option>

                        <option value="REMOVE_CLOSED_TO_ARRIVAL">
                          - Closed To Arrival
                        </option>
                        <option value="APPLY_CLOSED_TO_DEPARTURE">
                          + Closed To Departure
                        </option>
                        <option value="REMOVE_CLOSED_TO_DEPARTURE">
                          - Closed To Departure
                        </option>
                      </Field>
                    </div>
                  </div>
                  <div
                    className="col col-sm-3"
                    hidden={
                      !(
                        formState.values.property == "baseRate" ||
                        formState.values.property == "setRate" ||
                        formState.values.property == "owsBaseRate"
                      )
                    }
                  >
                    <div className="form-group">
                      <label htmlFor="baseRate">
                        {getRateLabel(formState.values.property)}
                      </label>
                      <Field
                        id="baseRate"
                        name="baseRate"
                        type="number"
                        step="1"
                        placeholder=""
                        className={getFieldClasses<BulkData>(
                          formState,
                          "baseRate",
                          "lg",
                        )}
                        validate={(value: any) =>
                          this.validate(value, formState)
                        }
                        // error={formState.errors.baseRate}
                      />
                      <div className="bulkValidation">
                        {formState.errors.baseRate}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col col-sm-3"
                    hidden={formState.values.property !== "OVERIDE_OWS"}
                  >
                    <div className="form-group">
                      <label htmlFor="owslabel">Label</label>
                      <Field
                        component="select"
                        className="form-control"
                        name="owslabel"
                        id="owslabel"
                      >
                        <option value={this.state.defaultOwsLabel}>
                          {this.state.defaultOwsLabel}
                        </option>
                        {owsOverrideOptions}
                      </Field>
                    </div>
                  </div>
                </div>

                <div className="mt-4 w-70">
                  <BulkUpdatePreview
                    hotelId={this.state.hotel.hotelId}
                    rate={
                      !(
                        formState.values.property == "baseRate" ||
                        formState.values.property == "setRate" ||
                        formState.values.property == "owsBaseRate"
                      )
                        ? 100
                        : formState.values.baseRate
                    }
                    selectable={
                      formState.values.property !== "baseRate" &&
                      formState.values.property !== "owsBaseRate" &&
                      formState.values.property !== "CLOSE_REWARDS" &&
                      formState.values.property !== "OPEN_REWARDS" &&
                      formState.values.property !== "OVERIDE_OWS"
                    }
                    manualOnly={formState.values.property == "setRate"}
                    onSelection={(selections) =>
                      this.setState({ restrictionSelections: selections })
                    }
                    appStore={appStore}
                  />
                </div>
                <div className="col d-flex justify-content-end mt-4">
                  <button
                    className="btn btn-secondary mr-4"
                    type="button"
                    onClick={() => this.discardAndExit()}
                  >
                    Discard
                  </button>
                  <LoadingButton
                    loading={this.state.displaySpinner}
                    sx={{
                      color: "white",
                      backgroundColor: "#ce0e29",
                      borderColor: "#ce0e29",
                      "&:hover": {
                        backgroundColor: "#a1081d",
                      },
                    }}
                    classes="btn btn-primary"
                    variant="outlined"
                    onClick={() => this.applyToYieldSheet(formState)}
                  >
                    Apply
                  </LoadingButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        {/* </div>
      </div> */}
      </Dialog>
    );
  }

  private prepareRewardsPayload(data: FormState): IBulkRewardsUpdate[] {
    let hotelId = this.state.hotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    let res = this.state.periods.map((p) => {
      return {
        start: p.start,
        end: p.end,
        hotelId,
        dow,
        active: data.property.indexOf("OPEN") != -1,
      };
    });
    return res;
  }
  private prepareOverideOwsPayload(
    data: PublishPayload,
  ): BulkOwsOverridePayload[] {
    let hotelId = this.state.hotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    let res = this.state.periods.map((p) => {
      return {
        ...data,
        start: p.start,
        end: p.end,
        baseRate: data.baseRate,
        dow,
        hotelId,
        label: data.owslabel,
      };
    });
    return res;
  }
  private prepareBaseRatePayload(data: PublishPayload): BulkUpdatePayload[] {
    let hotelId = this.state.hotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    let res = this.state.periods.map((p) => {
      return {
        ...data,
        start: p.start,
        end: p.end,
        baseRate: data.baseRate,
        dow,
        hotelId,
      };
    });
    return res;
  }
  private prepareOWSBaseRatePayload(data: PublishPayload): BulkUpdatePayload[] {
    let hotelId = this.state.hotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    let res = this.state.periods.map((p) => {
      return {
        weekDays: data.weekDays,
        baseRate: 0,
        start: p.start,
        end: p.end,
        owsBaseRate: data.baseRate,
        dow,
        hotelId,
      };
    });
    return res;
  }
  private prepareSetRatePayload(data: FormState): IBulkManualRateUpdate[] {
    let hotel = this.state.hotel;
    let hotelId = hotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    let results = this.state.periods.map((p) => {
      let v: IManualRateUpdate[] = [];
      return {
        dow,
        updates: v,
        hotelId,
        start: p.start,
        end: p.end,
      };
    });

    console.log(this.state.restrictionSelections);
    results.forEach((res) => {
      Object.keys(this.state.restrictionSelections)
        .map((roomtype) => {
          let i: IManualRateUpdate[] = [];
          let rps = Object.keys(
            this.state.restrictionSelections[roomtype].ratePlans,
          );
          rps.forEach((rp: string) => {
            i.push({
              hotelId,
              start: res.start,
              end: res.end,
              rate: data.baseRate * 100,
              invTypeCode: roomtype,
              ratePlanID: rp,
            });
          });
          return i;
        })
        .forEach((value) => {
          res.updates.push(...value);
        });
    });
    return results;
  }

  private prepareRestrictionsPayload(
    data: FormState,
  ): Promise<IRestrictionsUpdate[]> {
    return new Promise((resolve, reject) => {
      let hotel = this.state.hotel;
      let hotelId = hotel.hotelId;
      const actionName: "apply" | "remove" = !data.property.indexOf("APPLY_")
        ? "apply"
        : "remove";
      const daycount = data.property.indexOf("#")
        ? data.property.split("#")[1]
        : 0;
      const restrictionKey = data.property
        .replace(/^[A-Z]*_/, "")
        .split("#")[0] as RestrictionTypeKeys;
      const restriction: RestrictionToApply = {
        type: restrictions.TYPE[restrictionKey],
        dayCount: Number(daycount),
      };
      const dow: Dow = days.reduce(
        (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
        {},
      );
      const makeDayObj = (roomType: string) => ({
        restrictions: {
          [roomType]: {
            ratePlans: Object.keys(hotel.ratePlans).reduce(
              (acc, ratePlan) => ({ ...acc, [ratePlan]: {} }),
              {},
            ),
          },
        },
      });
      const roomTypeRestrictions = Object.keys(this.state.restrictionSelections)
        .filter(
          (roomType) => this.state.restrictionSelections[roomType].selected,
        )
        .map(async (roomType) => {
          let obj = makeDayObj(roomType);
          if (actionName === "apply") {
            obj = await restrictions.apply(obj, { ...restriction, roomType });
          } else {
            obj = await restrictions.remove(hotel.meta.globalRestriction, obj, {
              ...restriction,
              roomType,
            });
          }

          return obj;
        });
      const ratePlanRestrictions = Object.keys(this.state.restrictionSelections)
        .filter((roomType) => {
          const roomTypeData = this.state.restrictionSelections[roomType];
          return (
            !roomTypeData.selected &&
            Object.keys(roomTypeData.ratePlans).some(
              (ratePlan) => roomTypeData.ratePlans[ratePlan],
            )
          );
        })
        .map(async (roomType) => {
          let obj = makeDayObj(roomType);
          const roomTypeData = this.state.restrictionSelections[roomType];
          let rps = Object.keys(roomTypeData.ratePlans)
            .filter((ratePlan) => roomTypeData.ratePlans[ratePlan])
            .filter(
              (ratePlan) =>
                !hotel.ratePlans[ratePlan].restrictionExclusions ||
                hotel.ratePlans[ratePlan].restrictionExclusions.indexOf(
                  restriction.type,
                ) == -1,
            );
          for (let ratePlan of rps) {
            if (actionName === "apply") {
              obj = await restrictions.apply(obj, {
                ...restriction,
                roomType,
                ratePlan,
              });
            } else {
              obj = await restrictions.remove(
                hotel.meta.globalRestriction,
                obj,
                { ...restriction, roomType, ratePlan },
              );
            }
          }
          return obj;
        });

      Promise.all(ratePlanRestrictions).then((v) => {
        Promise.all(roomTypeRestrictions).then((w) => {
          const allRoomTypeRestrictions = Object.assign(
            {},
            ...[...v, ...w].map((o) => o.restrictions),
          );
          let result = this.state.periods.map((p) => {
            return {
              start: p.start,
              end: p.end,
              restrictions: allRoomTypeRestrictions,
              dow,
              hotelId,
            };
          });

          resolve(result);
        });
      });
    });
  }
}
function hasOWSBRRow(hotel: any) {
  return hotel.cm.channelConfig && hotel.cm.channelConfig.ows;
}

function getRateLabel(input: string) {
  switch (input) {
    case "baseRate":
      return "Base Rate";
    case "owsBaseRate":
      return "OWS Base Rate";
    default:
      return "Set Rate";
  }
}

export default BulkUpdate;
